<template>
    <div class="container-main">
        <div class="banner-box">



            <div class="title center">
                <span class="Medium text-start md-text-center">Flexible Pricing,</span>
                <span class="Large text-start md-text-center">Game-Changing Value</span>
            </div>

            <div class="price-box center">
                <div class="colum">
                    <div class="package package-top Paragraph-Medium">&nbsp;</div>
                    <div class="item-box item-box-starter-top">
                        <div class="starter H6-Medium">Starter</div>
                    </div>
                    <div class="item-box item-box-standard-top">
                        <div class="standard H5-Medium">Standard</div>
                    </div>
                    <div class="item-box item-box-enterprise-top">
                        <div class="enterprise H6-Medium">Enterprise</div>
                    </div>
                </div>
                <div class="outer">
                    <div class="colum">
                        <div class="package package-middle">Price per user
                            <div class="position-box">
                                <img @mouseover="showTips(0)" @mouseout="hideTips()"
                                    src="../assets/svg/exclamationMark.svg">
                                <div class="tip" :class="{ active: tipIndex == 0 }">{{ 'Per User Plans' }}</div>
                            </div>
                        </div>
                        <div class="item-box item-box-starter-middle">
                            <div style="padding-top:10px; padding-bottom:10px" class="starter H5-Medium">{{this.currencySymbol}} 4.99 <br>
                                <div style="font-size:20px"> user/month </div>
                            </div>

                        </div>
                        <div class="item-box item-box-standard-middle">
                            <div style="padding-top:10px; padding-bottom:10px" class="starter H5-Medium">{{this.currencySymbol}} 7.99 <br>
                                <div style="font-size:20px"> user/month </div>
                                <div class="billed-anually H6-Medium">(Annual Discount Available)</div>
                            </div>
                        </div>
                        <div class="item-box item-box-enterprise-middle">

                            <div class="enterprise H5-Medium" style="border-bottom: 2px solid #FFFFFF; "
                                @click="$router.push('/contact')">Contact Us</div>
                        </div>
                    </div>
                    <div class="priceUser" :class="{ expand: isExpand }">
                        <div class="colum" v-for="(item, index) in pricing.pricing" :key="index"
                            :class="{ expand1: item.expand, active: isExpand }">
                            <div class="box">
                                <div class="border-padding">
                                    <div :class="'row' + index" class="package Paragraph-Medium" v-if="item.package">{{
                                        item.
                                            package }}
                                        <div class="position-box">
                                            <img @mouseover="showTips(index + 1)" @mouseout="hideTips()"
                                                src="../assets/svg/exclamationMark.svg">
                                            <div class="tip" :class="{ active: tipIndex == index + 1 }">{{ tootips[index] }}
                                            </div>
                                            <div class="expand" v-if="item.expandList.length > 0" @click="expand()">{{
                                                expandText }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-box item-box-starter">
                                    <div :class="'row' + index" class="starter" v-if="item.starter.value">{{
                                        item.starter.value }}</div>
                                    <div class="starter starter-check" v-if="item.starter.check == 1"><img
                                            src="../assets/image/Component13.png"></div>
                                    <div class="starter" v-if="item.starter.check == 2"><img
                                            src="../assets/image/Component45.png"></div>
                                    <div class="btn btn-starter H6-Medium" v-if="item.starter.btn"
                                        @click="$router.push('/contact')">Get Started</div>
                                </div>
                                <div class="item-box item-box-standard">
                                    <div :class="'row' + index" class="standard" v-if="item.standard.value">{{
                                        item.standard.value }}</div>
                                    <div class="standard standard-check" v-if="item.standard.check == 1"><img
                                            src="../assets/image/Component13.png"></div>
                                    <div class="standard" v-if="item.standard.check == 2"><img
                                            src="../assets/image/Component45.png"></div>
                                    <div class="btn btn-standard Heading-Semibold" v-if="item.standard.btn"
                                        @click="$router.push('/contact')">Get Started</div>
                                </div>
                                <div class="item-box item-box-enterprise">
                                    <div :class="'row' + index" class="enterprise" v-if="item.enterprise.value">{{
                                        item.enterprise.value }}</div>
                                    <div class="enterprise enterprise-check" v-if="item.enterprise.check == 1"><img
                                            src="../assets/image/Component13.png"></div>
                                    <div class="enterprise" v-if="item.enterprise.check == 2"><img
                                            src="../assets/image/Component45.png"></div>
                                    <div class="btn btn-enterprise H6-Medium" v-if="item.enterprise.btn"
                                        @click="$router.push('/contact')">Get Started</div>
                                </div>
                            </div>

                            <div class="childList" ref="childList" :class="{ active: isExpand }"
                                v-if="item.expandList.length > 0">
                                <div class="colum" v-for="(i, m) in item.expandList" :key="m">
                                    <div class="border-padding">
                                        <div class="package Paragraph-Medium" v-if="i.package">{{ i.package }}
                                            <div class="position-box">
                                                <img @mouseover="showTipsChild(m)" @mouseout="hideTipsChild()"
                                                    src="../assets/svg/exclamationMark.svg">
                                                <div class="tip" :class="{ active: tipIndexChild == m }">{{ tooltipsChild[m]
                                                }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-box item-box-starter">
                                        <div class="starter" v-if="i.starter.value">{{ i.starter.value }}</div>
                                        <div class="starter starter-check" v-if="i.starter.check == 1"><img
                                                src="../assets/image/Component13.png"></div>
                                        <div class="starter" v-if="i.starter.check == 2"><img
                                                src="../assets/image/Component45.png"></div>
                                    </div>
                                    <div class="item-box item-box-standard">
                                        <div class="standard" v-if="i.standard.value">{{ i.standard.value }}</div>
                                        <div class="standard standard-check" v-if="i.standard.check == 1"><img
                                                src="../assets/image/Component13.png"></div>
                                        <div class="standard" v-if="i.standard.check == 2"><img
                                                src="../assets/image/Component45.png"></div>
                                    </div>
                                    <div class="item-box item-box-enterprise">
                                        <div class="enterprise" v-if="i.enterprise.value">{{ i.enterprise.value }}</div>
                                        <div class="enterprise enterprise-check" v-if="i.enterprise.check == 1"><img
                                                src="../assets/image/Component13.png"></div>
                                        <div class="enterprise" v-if="i.enterprise.check == 2"><img
                                                src="../assets/image/Component45.png"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PricesMobile />
        </div>

        <BottomCtaComponent />
    </div>
</template>
<script>
import pricing from '../json/pricing.json'
import tootips from '../json/tootip.json'
import BottomCtaComponent from './bottomCta.vue'
import PricesMobile from './PricesMobile.vue'
export default {
    name: 'frontPage',
    props: {
    },
    data() {
        return {

            isFixed: false,
            currencyName: '',
            currencySymbol: '',
            pricing: JSON.parse(JSON.stringify(pricing)),
            tootips: tootips.tootips,
            tooltipsChild: tootips.child,
            tipIndex: null,
            tipIndexChild: null,
            activeName: '',
            expandText: 'Expand',
            isExpand: false
        }
    },
    components: {
        BottomCtaComponent, PricesMobile
    },
    methods: {

        async detectLocation() {
            try {
                const response = await fetch('https://ipinfo.io?token=7ab381cccdd2d5');
                const data = await response.json();
                this.updateCurrencySymbol(data.country);
                console.log('done');
            } catch (error) {
                console.error('Error detecting location:', error);
            }
        },
        updateCurrencySymbol(location) {
            this.currencyName = {
                'GB': 'GBP',  // UK
                'US': 'USD',  // United States
                'FR': 'EUR'   // France
                // Add more mappings as needed
            }[location] || 'GBP';
            this.currencySymbol = {
                
                'GB': '£',  // UK
                'US': '$',  // United States
                'FR': '€'   // France
            
            }[location] || '£';
        },

        showTips(index) {
            this.tipIndex = index
        },
        showTipsChild(index) {
            // console.log(index);
            this.tipIndexChild = index
        },
        hideTipsChild() {
            this.tipIndexChild = null
        },
        hideTips() {
            this.tipIndex = null
        },
        expand() {
            if (this.expandText == 'Expand') {
                this.expandText = 'Collapse'
                this.isExpand = true
                setTimeout(() => {
                    this.$refs.childList[0].style.overflow = 'visible'
                }, 300);
            } else {
                this.isExpand = false
                this.expandText = 'Expand'
                this.$refs.childList[0].style.overflow = 'hidden'
            }
        },
        scrollEvent() {
            if (window.scrollY > 60.5) {
                this.isFixed = true
            }
            if (window.scrollY < 60.5) {
                this.isFixed = false
            }
        },
        toggleCollapse(name) {
            this.activeName = (this.activeName === name) ? null : name;
        },





    },
    created() {
        document.addEventListener('scroll', this.scrollEvent);
        this.detectLocation();
    },
    mounted() {
        console.log(this.tooltipsChild)
    }
}
</script>

<style scoped lang="less">
@import '../css/font.css';


.center {
    margin: 0 auto;
    width: 80%;
}

.container-main {
    margin: 0 auto;
    background: #F2F3F4;
    max-width: 100vw;
    overflow: hidden;
}

.container {
    background: #F2F3F4;
}

.banner-box {
    overflow: hidden;
    width: 100%;
    background: url('../assets/image/main-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 90%;

    .fixed-box {
        width: 100%;
        z-index: 99;
        background: #0C5C6A;

        &.active {
            position: fixed;
            background-color: rgba(255, 255, 255, 0.8);

            .menu {
                a {
                    color: #000000;

                    &:last-child {
                        background: linear-gradient(0deg, #2AA1B6, #2AA1B6);
                        border: .5px solid #2AA1B6;
                    }
                }
            }

            .header {
                padding-bottom: 16px;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 16px;

        .menu {
            a {
                //styleName: Sec -WEB/Paragraph Regular/Medium;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                line-height: 44.5px;
                letter-spacing: 0em;
                text-align: center;
                color: #FFFFFFCC;
                text-decoration: none;
                padding: 8px 16px;

                &.active {
                    border-bottom: 2px solid #fff;
                    font-weight: 600;
                }

                &:last-child {
                    margin-right: 0px;
                    border: .5px solid #fff;
                    padding: 8px 24px;
                    border-radius: 4px;
                }
            }
        }
    }

    .title {
        margin-top: 36px;
        color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .price-box {
        background: #0C5C6A;
        border-radius: 16px;
        margin-top: 60px;
        margin-bottom: 60px;

        .package {
            padding: 12px 0px;

            img {
                width: 18px;
                height: 18px;
                margin-left: 8px;
            }
        }

        .package-top {
            padding: 12px 20px;
            color: #FFFFFF80;

        }

        div {
            box-sizing: border-box;
        }

        .outer {
            background: #0D4953;
            border-radius: 16px;

            .item-box-standard {
                background: #F8F9F9;
            }

            .item-box-starter-middle {
                padding: 0px 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            .item-box-standard-middle {
                padding: 0px 30px;
                border-right: 8px solid #5298E0;
                border-left: 8px solid #5298E0;
                background: #F2F3F4;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #5298E0;
                font-weight: 600;
            }

            .item-box-enterprise-middle {

                cursor: pointer;
                padding: 0px 30px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                // border-bottom: #52CBE0;
                // &:hover{
                //     border-bottom: #FFFFFF;
                // }
            }

            .package-middle {
                padding: 12px 20px;
                color: #fff;

                .position-box {
                    position: relative;
                    display: flex;
                }

                .tip {
                    position: absolute;
                    width: 200px;
                    left: 0px;
                    top: 60px;
                    border-radius: 0px 16px 16px 16px;
                    background: #0D4953;
                    padding: 8px;
                    transition: all .2s linear;
                    opacity: 0;

                    &.active {
                        transition: all .2s linear;
                        left: 30px;
                        top: 30px;
                        opacity: 100%;
                        z-index: 1001;
                    }
                }
            }
        }

        .colum {
            .item-box-standard-top {
                padding: 0px 30px;
                border-top: 8px solid #5298E0;
                border-right: 8px solid #5298E0;
                border-left: 8px solid #5298E0;
                border-radius: 16px 16px 0px 0px;
                background: #F2F3F4;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .item-box-starter-top {
                padding: 0px 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            .item-box-enterprise-top {
                padding: 0px 30px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }

            display: flex;

            >div {
                width: 25%;
            }

            .package {
                display: flex;
                align-items: center;
            }

            .item-box-standard {
                border-left: 8px solid #5298E0;
                border-right: 8px solid #5298E0;
            }
        }

        .priceUser {
            >.colum {
                display: flex;
                flex-direction: column;

                .box {
                    display: flex;
                    width: 100%;
                    margin-top: -1px;

                    >div {
                        width: 25%;
                    }
                }

                .childList {
                    margin-left: 20px;
                    width: calc(100% - 20px);
                    box-sizing: border-box;
                    border-radius: 16px 0px 0px 16px;
                    overflow: hidden;
                    max-height: 0px;
                    transition: all .4s ease 0s;

                    &.active {
                        max-height: 1280px;
                    }

                    >.colum {
                        &:nth-child(1) {
                            .border-padding {
                                border-radius: 16px 0px 0px 0px;
                            }
                        }

                        &:last-child {
                            .border-padding {
                                border-radius: 0px 0px 0px 16px;
                            }
                        }

                        >div {
                            background-color: #F2F3F4;
                            width: calc((100% + 20px)/4);

                            &:nth-child(1) {
                                width: calc((100% + 20px)/4 - 20px);
                            }

                            &.border-padding {
                                background: #0D4953;
                            }
                        }

                        &:last-child {
                            .item-box-standard {
                                border-bottom: none;
                                border-radius: 0px;
                            }

                            .item-box-starter {
                                border-radius: 0px;
                                margin-bottom: 0px;

                                .starter {
                                    border-bottom: none;
                                }
                            }

                            .item-box-enterprise {
                                margin-bottom: 0px;
                                border-radius: 0px;
                            }

                            .enterprise,
                            .standard,
                            .starter {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

            .position-box {
                position: relative;
                display: flex;

                @media only screen and (min-width: 900px) and (max-width: 1264px) {
                    flex-direction: column;
                }
            }

            .tip {
                position: absolute;
                width: 200px;
                left: 0px;
                top: 60px;
                border-radius: 0px 16px 16px 16px;
                background: #0D4953;
                padding: 8px;
                transition: all .2s linear;
                opacity: 0;

                &.active {
                    transition: all .2s linear;
                    left: 30px;
                    top: 30px;
                    opacity: 100%;
                    z-index: 1001;
                }
            }

            .expand {
                margin-left: 34px;
                color: #52CBE0;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: right;
                text-decoration: underline;
                cursor: pointer;

                @media only screen and (min-width: 900px) and (max-width: 1264px) {
                    margin-left: 0px;
                }
            }

            .btn {
                margin-bottom: 20px;
                margin-top: 10px;
                // margin-left: 39px;
                padding: 12px 38px;
                text-align: center;
                border-radius: 4px;
                border: 1px solid #BCC5EA;
                cursor: pointer;
            }

            .btn-standard {
                background: #5298E0;
                color: #FFFFFF;
            }

            .starter-check {
                img {
                    border-radius: 50%;
                    background: #E05288;
                }
            }

            .standard-check {
                img {
                    border-radius: 50%;
                    background: #5298E0;
                }
            }

            .enterprise-check {
                img {
                    border-radius: 50%;
                    background: #255A91;
                }
            }

            .border-padding {
                padding: 0 20px;
            }

            .colum {
                .item-box-enterprise {
                    margin-right: 8px;
                }

                .item-box {
                    background: #F8F9F9;
                    padding: 0px 30px;
                    text-align: center;
                }

                background: #0F363C;

                &:nth-child(1) {
                    border-radius: 16px 16px 0px 0px;
                }

                &:last-child {
                    border-radius: 0px 0px 16px 16px;
                }

                .package {
                    border-bottom: 1px solid #4B686D80;
                    color: #fff;
                }

                .starter,
                .standard,
                .enterprise {
                    cursor: pointer;
                    padding: 12px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #D5DBE0;

                }

                &:last-child {
                    .item-box-standard {
                        border-bottom: 8px solid #5298E0;
                        border-radius: 0px 0px 10px 10px;
                    }

                    .item-box-starter {
                        border-radius: 0px 0px 0px 16px;
                        margin-bottom: 8px;


                        .starter {
                            border-bottom: none;
                        }
                    }

                    .item-box-enterprise {
                        margin-bottom: 8px;
                        border-radius: 0px 0px 16px 0px;
                    }

                    .enterprise,
                    .standard,
                    .starter {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}


.billed-anually {
    font-size: 13px;
}

.FAQ {
    margin-top: 100px;

    .FAQ-title {
        display: flex;

        .right {
            margin-left: 8.5%;

            .bottom {
                margin-top: 10px;
                padding: 12px 16px;
                display: flex;
                color: #0C5C6A;
                background: #FFFFFF;
                width: 140px;
                border-radius: 4px;
                cursor: pointer;

                img {
                    margin-left: 16px;
                }
            }
        }

        .bottom.Regular {
            width: max-content;
        }

        .left {
            text-wrap: nowrap;
        }
    }

    .FAQ-content {
        padding-top: 40px;
        padding-bottom: 80px;

        /deep/.el-collapse-item {
            border-bottom: 1px solid #00000033;

            &:last-child {
                border-bottom: none;
            }
        }

        /deep/ .el-collapse-item__header {
            background: #FFFFFF80;
            height: 72px;
            padding: 16px 24px;
            font-family: Work Sans;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: -0.02em;
            text-align: center;
            box-sizing: border-box;
            border-bottom: 1px solid #00000033;
            color: #30333E;

            &.is-active {
                border-bottom: none;
                background: #fff;
                color: #000;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        /deep/.el-collapse-item__content {
            background: #FFFFFF80;
            padding-left: 24px;
            padding-top: 10px;
        }

        .text {
            text-align: start;
            color: #000;
        }
    }
}

.bottom-margin {
    position: relative;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    // margin-left: 20px;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // width: 100%;
}

.pfaq-item {
    // margin-bottom: 10px;
    background: var(--Cloudworks-Neutral-White, #FFF);
}

.pfaq-title {
    cursor: pointer;
    // background-color: #f0f0f0;
    padding: 20px;
    color: var(--Cloudworks-Grey-Original, #545D7D);

    /* Pri -WEB/Heading H6/Medium */
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.4px;
}

.pfaq-content {
    padding: 0 20px 20px 20px;
    // border: 1px solid #ddd;
    color: var(--Cloudworks-Neutral-Black, #000);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.expand-sign {
    position: relative;
    // top: 50%;
    right: 10px;
    float: right;
    // transform: translateY(-50%);
    transition: transform 0.3s;
}

.contract-sign {
    position: relative;
    // top: 50%;
    right: 10px;
    float: right;
    // transform: translateY(-50%);
    transition: transform 0.3s;
}



.row13 {
    margin-right: 16px;
}

.row0{
    border-bottom: 1.5px solid #D5DBE0;
}




.md-text-center {
    text-align: center;
}

@media screen and (max-width:900px) {

    // On small screens
    .md-text-center {
        text-align: start;
    }

    .center {
        width: 90%;
    }

    .FAQ {
        .FAQ-title {
            flex-direction: column;

            .right {
                margin-left: 0;
            }
        }
    }

    /*  */
    .getStarted {
        height: 568px;
        background-size: 100% 568px;

        .footerImage {
            display: none;
            top: unset;
            bottom: 0;
        }

    }

    .price-box {
        display: none;
    }
}

@media screen and(max-width:900px) {
    .Large {
        font-size: 28px;
        line-height: unset;
        font-weight: 500;
    }

    .Medium {
        font-size: 20px;
        font-weight: 400;
        line-height: unset;
    }
}
</style>
  